.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  background-color: #eef0f3; /* Light background */
  
  transition: background-color 0.3s ease;
}

/* Dark mode */
body.dark {
  background-color: #0c131a; /*Dark background*/

  /* background-color: linear-gradient(90deg, rgba(2,0,36,1) 11%, rgba(23,37,84,1) 51%, rgba(0,212,255,1) 150%); */

}
