body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eef0f3;

  
}


html {
  transition: background-color 0.3s ease, color 0.3s ease; /* Optional smooth transition */
}

body.dark {
  background-color: #0c131a; 
  /* background-color: linear-gradient(90deg, rgba(2,0,36,1) 11%, rgba(23,37,84,1) 51%, rgba(0,212,255,1) 150%); */
  

}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
